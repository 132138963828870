
/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useLocation} from 'react-router-dom';
import {RouteContext, CacheStorage, useWindowScroll} from '../index';

/**
 * Component for scroll to top if change location
 */
export function ScrollRecovery(props) {

    const {pathname} = useLocation();
    let {y: scrollY} = useWindowScroll()
    const {route} = React.useContext(RouteContext)
    const [key, setKey] = React.useState(null);

    React.useEffect(() => {
        if (key) {
            CacheStorage.set(key, scrollY, false, true)
        }
    }, [key, scrollY]);

    React.useEffect(() => {
        const key = `scroll-recovery-${pathname}`
        const el = document.getElementById("root")
        if (route.type === 'POP') {
            el.scrollTo(0, CacheStorage.get(key, false));
        }
        setKey(key)
    }, [pathname, route]);

    return null;
}

ScrollRecovery.propTypes = {};
